<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        {{ $trans("zoom_disconnect_info") }}
      </v-col>
    </v-row>
    <v-row v-if="loggedUser.employee.zoom_user_email">
      <v-col class="text-h6 text-center">
        {{ loggedUser.employee.zoom_user_email }}
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import api from "@/lib/calendesk-js-library/api/api";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ConnectedZoom",
  mixins: [sharedActions],
  data() {
    return {
      isSaving: false,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getUserData: "auth/getUserData",
      fetchRequiredAppComponents: "setup/fetchRequiredAppComponents",
    }),
    handleSave() {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans("zoom_disconnect_button_title"),
        warning: this.$trans("zoom_disconnect_dialog_info"),
        confirmationText: this.$trans("disconnect_button_title"),
        confirmationButtonColor: "error",
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true);
          api
            .disableZoomorEmployee()
            .then(() => {
              this.getUserData().finally(() => {
                this.fetchRequiredAppComponents();
                this.closeConfirmDialog();
                this.$emit("close");
                successNotification("zoom_disconnect_success_message");
              });
            })
            .catch((error) => {
              errorNotification(null, error);
            })
            .finally(() => {
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
              this.$emit("close");
            });
        },
      });
    },
  },
};
</script>
